import React, { Component } from "react"
import "./InnerHero.css"
export default class InnerHero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      desc: props.desc,
      preLoad: "is-preload",
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        preLoad: "",
      })
    }, 0)
  }
  render() {
    return (
      <div className={`${this.state.preLoad} hero-overlay`}>
        <div className="content-box">
          <h1>{this.state.title}</h1>
          <span className="desc">{this.state.desc}</span>
        </div>
      </div>
    )
  }
}
