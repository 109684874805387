import React from "react"
import Title from "./Title"
import Recipe from "./Recipe"
import { Link } from "gatsby"
export const Recipes = ({ recipes }) => {
  return (
    <section className="section center-align">
      <div className="container section">
        <br />
        <br />
        <div className="row">
          {recipes.map((recipe, index) => {
            return <Recipe key={index} {...recipe} />
          })}
        </div>
      </div>
    </section>
  )
}
export default Recipes
