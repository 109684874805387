import React, { useEffect, useState } from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import InnerHero from "./InnerHero"
import "./Hero.css"
// const query = graphql`
//   {
//     file(relativePath: { eq: "hero.PNG" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

const Hero = props => {
  // const {
  //   file: {
  //     childImageSharp: { fluid },
  //   },
  // } = useStaticQuery(query)
  return (
    <BackgroundImage
      fluid={props.fluid}
      style={{ height: `${props.height}` }}
      className="masthead"
    >
      <InnerHero title={props.title} desc={props.desc} />
    </BackgroundImage>
  )
}

export default Hero
