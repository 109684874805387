import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import "./Recipe.css"
const Recipe = ({ title, description, image, slug, time }) => {
  return (
    <div className="col s12 m6">
      <div className="card hoverable">
        <div className="card-image">
          <Image fluid={image.childImageSharp.fluid} className="card-img" />

          <span className="card-title">{title}</span>
        </div>
        <div className="card-content left-align">
          <p>{description}</p>
        </div>
        <div className="card-action">
          <div className="full-bot">
            <Link to={`/recipes/${slug}`} className="left">
              View Recipe
            </Link>

            <span className="right">Time: {time}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Recipe
