import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import Recipes from "../components/Recipes"
import SEO from "../components/SEO"
export default ({ data }) => {
  const {
    allStrapiRecipes: { nodes: recipes },
    file: {
      childImageSharp: { fluid },
    },
  } = data
  return (
    <Layout>
      <SEO title="Recipes" description="Try our recipes" />
      <Hero height="40vh" title="recipes" desc="give one a try" fluid={fluid} />
      <section className="blog-page">
        <Recipes recipes={recipes} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiRecipes {
      nodes {
        description
        id
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slug
        title
        time
      }
    }
    file(relativePath: { eq: "aux1.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
